<template>
  <div class="container">
    <div class="summary">
      <div class="heading">
        <h2>{{ question }}</h2>
        <p>Ecrit par dok.ma</p>
      </div>
      <div class="icon" @click="goTo(link)">
        <svg
          width="28"
          height="28"
          :class="isOpen ? 'open' : 'close'"
          viewBox="0 0 28 28"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="m10.5 21 7-7-7-7"
            stroke="#333638"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["link", "question"],
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    goTo(link) {
      window.location = link;
    },
  },
};
</script>
<style scoped>
.container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-radius: 8px;
  border: solid 1px #f1f1f1;
  background-color: #fff;
}
.summary {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  justify-content: space-between;
}
.heading {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: self-start;
  gap: 2px;
}
.heading h1 {
  font-size: 18px;
  font-weight: 500;
  text-align: center;
  color: #111;
}
.heading p {
  font-size: 14px;
  text-align: left;
  color: #454649;
  text-align: justify;
}
.icon {
  cursor: pointer;
}
.icon svg.open {
  transform: rotate(90deg);
}
.icon svg.close {
  transform: rotate(0deg);
}
</style>
