<template>
  <form v-on:submit.prevent="onClick">
    <!-- Form -->
    <div class="2">
      <div>
        <div class="flex justify-between items-center mb-5">
          <img
            src="/icons/checkIcon.png"
            alt="checked"
            class="block xl:hidden"
            v-if="
              data.isAccepte &&
              data.certification &&
              data.email &&
              data.gender &&
              data.firstName &&
              data.lastName &&
              data.mobile
            "
          />
        </div>
        <div class="flex gap-x-8 mb-5">
          <div class="flex items-center">
            <input
              type="radio"
              id="homme"
              class="
                form-radio
                border border-solid border-gray-400
                text-xl
                mr-2
                focus:outline-none focus:shadow-none
              "
              :class="errors.gender ? 'border-red-500' : ''"
              v-model="data.gender"
              value="MALE"
            />
            <label for="homme" class="font-EffraM text-xl">
              {{ $t("homme") }}
            </label>
          </div>
          <div class="flex items-center">
            <input
              type="radio"
              id="femme"
              class="
                form-radio
                border border-solid border-gray-400
                text-xl
                mr-2
                focus:outline-none focus:shadow-none
              "
              :class="errors.gender ? 'border-red-500' : ''"
              v-model="data.gender"
              value="FEMALE"
            />
            <label for="femme" class="font-EffraM text-xl">
              {{ $t("femme") }}
            </label>
          </div>
        </div>
        <div class="flex flex-col md:flex-row gap-x-5 mb-5">
          <!--- First name ---->
          <dok-input
            size="lg"
            :d-placeholder="$t('lastName')"
            d-type="text"
            :d-model.sync="data.lastName"
            :custom-class="errors.lastName ? 'border-red-500' : ''"
            :label="$t('lastName')"
          >
          </dok-input>
          <!--- Last name ---->
          <dok-input
            size="lg"
            :d-placeholder="$t('firstName')"
            d-type="text"
            :d-model.sync="data.firstName"
            :custom-class="errors.firstName ? 'border-red-500' : ''"
            :label="$t('firstName')"
          >
          </dok-input>
        </div>

        <!--- Specialite ---->
        <div class="flex flex-col w-full mb-5">
          <label for="ville" class="font-EffraR text-base text-gray-800">
            {{ $t("patient.specialite") }}
          </label>
          <multiselect
            :searchable="true"
            :internal-search="true"
            :preserve-search="true"
            :multiple="true"
            v-model="data.landingPage.address.ObjectSpecialities"
            :options="specialites"
            :placeholder="$t('patient.specialite')"
            track-by="name"
            label="name"
            @input="onChangeSpecialty"
            class="doksingupselect"
            :class="errors['landingPage.specialities'] ? 'has-error' : ''"
          >
            <template v-slot:noResult> {{ $t("no_result") }} </template>
          </multiselect>
        </div>

        <!--- City ---->
        <div class="flex flex-col xl:flex-row xl:gap-x-5 mb-5">
          <div class="flex flex-col w-full xl:w-11/12 mb-5 xl:mb-0">
            <label for="ville" class="font-EffraR text-base text-gray-800">{{
              $t("patient.ville")
            }}</label>
            <multiselect
              :searchable="true"
              :internal-search="true"
              :preserve-search="true"
              :multiple="false"
              v-model="data.landingPage.objectCity"
              :options="citys"
              :placeholder="$t('patient.ville')"
              track-by="name"
              label="name"
              @input="onChangeCity"
              :class="errors['landingPage.address.city'] ? 'has-error' : ''"
              class="doksingupselect"
            >
              <template v-slot:noResult>
                {{ $t("no_result") }}
              </template>
              <slot name="caret">X</slot>
            </multiselect>
          </div>
          <div class="w-full xl:w-100">
            <dok-input
              size="lg"
              d-placeholder="00000"
              d-type="text"
              :d-model.sync="data.landingPage.address.zipCode"
              :custom-class="
                errors['landingPage.address.zipCode'] ? 'border-red-500' : ''
              "
              :label="$t('patient.postal')"
            >
            </dok-input>
          </div>
        </div>
        <dok-input
          size="lg"
          :d-placeholder="$t('phone_pro')"
          d-type="tel"
          d-pattern="(\+\d{1,3}\s?)?((\(\d{3}\)\s?)|(\d{3})(\s|-?))(\d{3}(\s|-?))(\d{4})(\s?(([E|e]xt[:|.|]?)|x|X)(\s?\d+))?"
          :d-model.sync="data.mobile"
          :label="$t('phone_pro')"
          :custom-class="errors.mobile ? 'border-red-500' : ''"
        >
          <template v-slot:rightIcon>
            <i
              class="far fa-question-circle cursor-pointer px-3 tooltipinfo"
              v-tooltip="`${$getInfoBull('mobile')}`"
            ></i>
          </template>
          <template v-slot:leftIcon>
            <img src="/icons/tablet.png" :alt="$t('phone_pro')" />
          </template>
        </dok-input>
        <dok-input
          size="lg"
          :d-placeholder="$t('signin.email')"
          d-type="email"
          :d-model.sync="data.email"
          :label="$t('signin.email')"
          :custom-class="errors.email ? 'border-red-500' : ''"
        >
          <template v-slot:leftIcon>
            <img src="/icons/mail.png" alt="email" />
          </template>
        </dok-input>
        <!--- Address ---->
        <dok-input
          size="lg"
          :d-placeholder="$t('patient.address')"
          d-type="text"
          :d-model.sync="data.landingPage.address.label"
          :custom-class="
            errors['landingPage.address.label'] ? 'border-red-500' : ''
          "
          :label="$t('patient.address')"
        >
        </dok-input>

        <div>
          <dok-input
            size="lg"
            :d-placeholder="$t('signin.password')"
            :d-type="!data.showPassword ? 'password' : 'text'"
            :d-model.sync="data.password"
            :label="$t('signin.password')"
            :custom-class="
              errors.password || errors.passwordStrong ? 'border-red-500' : ''
            "
          >
            <template v-slot:rightIcon>
              <img
                src="/icons/eye.png"
                class="cursor-pointer"
                @click="data.showPassword = !data.showPassword"
                alt="voir le mot de passe"
              />
            </template>
          </dok-input>
          <div
            v-if="data.password.length"
            class="h-1 rounded-full my-2"
            :style="`width: ${percentPassword()}%; background: ${percentBackground(
              percentPassword()
            )}`"
          ></div>
          <div class="flex flex-col" v-if="data.password.length">
            <span
              class="flex flex-row gap-x-1 items-center font-EffraR"
              :class="validation.has_length ? 'text-green-500' : 'text-red-500'"
            >
              <i class="far fa-check-circle" v-if="validation.has_length"></i
              ><i class="far fa-times-circle" v-if="!validation.has_length"></i>
              {{ $t("validation_password.moins_chart") }}
            </span>
            <span
              class="flex flex-row gap-x-1 items-center font-EffraR"
              :class="validation.has_number ? 'text-green-500' : 'text-red-500'"
            >
              <i class="far fa-check-circle" v-if="validation.has_number"></i
              ><i class="far fa-times-circle" v-if="!validation.has_number"></i>
              {{ $t("validation_password.chiffres") }}
            </span>
            <span
              class="flex flex-row gap-x-1 items-center font-EffraR"
              :class="
                validation.has_lowercase ? 'text-green-500' : 'text-red-500'
              "
            >
              <i class="far fa-check-circle" v-if="validation.has_lowercase"></i
              ><i
                class="far fa-times-circle"
                v-if="!validation.has_lowercase"
              ></i>
              {{ $t("validation_password.minuscules") }}
            </span>
            <span
              class="flex flex-row gap-x-1 items-center font-EffraR"
              :class="
                validation.has_uppercase ? 'text-green-500' : 'text-red-500'
              "
            >
              <i class="far fa-check-circle" v-if="validation.has_uppercase"></i
              ><i
                class="far fa-times-circle"
                v-if="!validation.has_uppercase"
              ></i>
              {{ $t("validation_password.majuscules") }}
            </span>
            <span
              class="flex flex-row gap-x-1 items-center font-EffraR"
              :class="
                validation.has_special ? 'text-green-500' : 'text-red-500'
              "
            >
              <i class="far fa-check-circle" v-if="validation.has_special"></i
              ><i
                class="far fa-times-circle"
                v-if="!validation.has_special"
              ></i>
              {{ $t("validation_password.caracteres_speciaux") }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- End Form -->

    <!-- Actions -->
    <div class="w-full">
      <div class="flex flex-col w-full">
        <p
          style="
            font-size: 12px;
            line-height: 1.5;
            text-align: left;
            color: #767676;
          "
        >
          Conformément à la loi 09-08, vous disposez d'un droit d'accès, de<br />
          rectification et d'opposition au traitement de vos données<br />
          personnelles. Ce traitement a été autorisé par la CNDP sous le
          n°A-101/2021
        </p>
        <br />
        <div class="flex flex-row items-start">
          <input
            type="checkbox"
            class="
              form-checkbox
              border-2 border-solid border-gray-400
              rounded-none
              text-2xl
              mr-2
              focus:outline-none focus:shadow-none
            "
            id="terms"
            v-model="data.isAccepte"
          />
          <label
            for="terms"
            class="text-lg font-EffraM xl:font-EffraR text-black"
            >{{ $t("condition_partone") }}
            <span
              class="cursor-pointer"
              style="color: #1f49ad"
              @click="onModal('CGU')"
              >les conditions générales d'utilisation, </span
            ><br />
            {{ $t("condition_parttwo") }}</label
          >
        </div>
        <br />
        <div class="flex flex-row items-start">
          <input
            type="checkbox"
            class="
              form-checkbox
              border-2 border-solid border-gray-400
              rounded-none
              text-2xl
              mr-2
              focus:outline-none focus:shadow-none
            "
            id="certification"
            v-model="data.certification"
          />
          <label
            for="certification"
            class="text-lg font-EffraM xl:font-EffraR text-black"
          >
            {{ $t("certification") }}
          </label>
        </div>
        <br />
        <dok-button
          type="submit"
          size="md"
          custom-class="uppercase"
          @click.native="onClick"
        >
          Je découvre dok Pro
        </dok-button>
      </div>
    </div>
  </form>
</template>

<script>
import algoliasearch from "algoliasearch/lite";
export default {
  data() {
    return {
      errors: {
        landingPage: {
          address: {},
        },
      },
      modal: {
        is: false,
        type: null,
      },
      data: {
        password: "",
        isAccepte: false,
        certification: false,
        role: "DOCTOR",
        showPassword: false,
        landingPage: { address: { ObjectSpecialities: [] }, specialities: [] },
        professionalInfo: {},
      },
      validation: {
        has_number: false,
        has_lowercase: false,
        has_uppercase: false,
        has_special: false,
        has_length: false,
      },
      citys: [],
      specialites: [],
      algoliaKeys: algoliasearch(
        "2BJUOM1BDB",
        "7906f008c0346b78f39d051fa293387f"
      ),
    };
  },
  watch: {
    validation: {
      handler(newValue, oldValue) {
        this.percentPassword();
      },
      deep: true,
    },
    "data.password"(newVal, oldVal) {
      this.validation.has_number = /\d/.test(this.data.password);
      this.validation.has_lowercase = /[a-z]/.test(this.data.password);
      this.validation.has_uppercase = /[A-Z]/.test(this.data.password);
      this.validation.has_length = this.data.password.length > 7;
      this.validation.has_special = /[!@#\$%\^\&*\)\(+=._-]/.test(
        this.data.password
      );
    },
  },
  created() {
    this.onCitys();
    this.onSpecialites();
  },
  methods: {
    percentPassword() {
      let precent = 0;
      if (this.validation.has_number) precent = precent + 20;
      if (this.validation.has_lowercase) precent = precent + 20;
      if (this.validation.has_uppercase) precent = precent + 20;
      if (this.validation.has_special) precent = precent + 20;
      if (this.validation.has_length) precent = precent + 20;

      return precent;
    },
    percentBackground(val) {
      if (val === 0) return "#DDD";
      if (val === 20) return "#EF4444";
      if (val === 40) return "#DB2777";
      if (val === 60) return "#D97706";
      if (val === 80) return "#fbbf24";
      if (val === 100) return "#10B981";
    },
    onChangeCity(value) {
      this.data.landingPage.address.city = value ? value.objectID : null;
    },
    onChangeSpecialty(value) {
      let specialites = value.map((spec) => {
        return spec.objectID;
      });
      this.data.landingPage.specialities = specialites;
    },
    onModal(type) {
      this.modal = { type, is: true };
    },
    async onCitys() {
      const index = this.algoliaKeys.initIndex(
        "dokma_dev_cities_principal_first"
      );
      const data = await index.search("", {
        hitsPerPage: 618,
      });
      this.citys = data.hits;
    },
    async onSpecialites() {
      const index = this.algoliaKeys.initIndex("dokma_dev_specialities");
      const data = await index.search("", {
        hitsPerPage: 618,
      });
      this.specialites = data.hits;
    },
    async onClick() {
      event.preventDefault();

      if (!this.data.isAccepte || !this.data.certification) {
        return this.$vs.notify({
          time: 4000,
          text: "Merci de cocher les cases pour finaliser la création de votre compte",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-triangle",
        });
      }

      if (this.percentPassword() !== 100) {
        this.errors = {
          passwordStrong: "Password not valid",
        };

        return this.$forceUpdate();
      }

      this.$vs.loading();

      for (let item of this.data.landingPage.address.ObjectSpecialities) {
        if (item.name == "Pharmacie") {
          this.data.role = "PHARMACY";
          break;
        }
        if (item.name == "Biologiste (Laboratoire d'analyse)") {
          this.data.role = "LABORATORY";
          break;
        }
      }

      const { data } = await this.$store.dispatch("user/REGISTER", this.data);
      // Validation Inputs
      if (!data.ok) {
        this.$vs.loading.close();
        this.$vs.notify({
          time: 4000,
          text: data.ok ? "Compte créé avec succès" : data.message,
          color: data.ok ? "success" : "danger",
          iconPack: "feather",
          icon: "icon-check",
        });
        if (data.errors && Object.keys(data.errors).length) {
          return (this.errors = data.errors);
        }
      }
      if (data.ok) {
        if (!data.data.isActive) {
          this.$router.push({
            name: "verficationEmail",
            query: {
              email: data.data.email,
            },
          });
        }

        this.errors = [];
        this.data = {
          password: "",
          isAccepte: false,
          certification: false,
          role: "DOCTOR",
          showPassword: false,
          landingPage: {
            address: { ObjectSpecialities: [] },
            specialities: [],
          },
          professionalInfo: {},
        };
      }
    },
    async findSpecialite(term) {
      const { ok, data } = await this.$store.dispatch(
        "doctor/GET_SPECIALITIES_LIST",
        {
          params: {
            term,
            limit: 1,
          },
        }
      );
      if (ok && data[0]) {
        this.data.landingPage.address.ObjectSpecialities.push({
          name: data[0].name,
          slug: data[0].slug,
          objectID: data[0]._id,
        });
        this.onChangeSpecialty(
          this.data.landingPage.address.ObjectSpecialities
        );
      }
    },
    async onInpeSearch() {
      event.preventDefault();
      if (!this.data.professionalInfo.INPE) {
        return this.$vs.notify({
          time: 4000,
          text: "Please enter the INPE",
          color: "danger",
          iconPack: "feather",
          icon: "icon-alert-triangle",
        });
      }
      this.data.firstName = null;
      this.data.lastName = null;
      this.data.fix = null;
      this.data.landingPage.address.label = null;
      this.data.landingPage.address.ObjectSpecialities = null;
      this.$vs.loading();
      await this.$store.dispatch("doctor/PREFILL_BY_INPE", {
        params: { inpe: this.data.professionalInfo.INPE },
        onData: ({ ok, data }) => {
          if (!ok) {
            return this.$vs.notify({
              time: 4000,
              text: "L'INPE Entry n'est pas enregistré chez nous",
              color: "danger",
              iconPack: "feather",
              icon: "icon-alert-triangle",
            });
          }
          if (ok) {
            let fullname = data.nom.split(" "),
              firstName = fullname.pop(),
              lastName = fullname.join(" ") || "";
            this.data.firstName = firstName || null;
            this.data.lastName = lastName || null;
            this.data.fix = data.phone;
            this.data.landingPage.address.label = data.address;
            this.data.role = data.role || "PATIENT";
            if (data.role === "PHARMACY" || data.role === "LABORATORY") {
              this.data.localCommercialName = data.nom;
            }
            if (data.speciality) {
              this.findSpecialite(data.speciality);
            }
            this.$forceUpdate();
          }
        },
      });
      this.$vs.loading.close();
    },
  },
};
</script>
